<template>
    <!-- Create interpreter modal start -->
    <GlobModal
       :width="'1000px'"
       :title="interpreter && interpreter.Id ? $t('interpreters.edit') : $t('interpreters.create')"
       @close="$emit('close')"
       :show="true"
   >          
   <GlobSpinner :isLoading="isLoading"></GlobSpinner>      
        <GlobRow class="mt-4">
                <GlobCol :medium="4">
                    <label>{{$t('interpreters.name')}}*
                        <InputDynamic  
                            :type="'text'"
                            :placeholder="$t('interpreters.name')"
                            v-model="interpreter.Name"
                        />
                    </label>
                </GlobCol>
                <GlobCol :medium="4">
                    <label>{{$t('interpreters.interpreter_type')}}*
                        <InputDynamic  
                            :type="'virtualselect'"
                            :placeholder="$t('interpreters.interpreter_type')"
                            :data="interpreterTypes"
                            v-model="interpreter.InterpreterId"
                        />
                    </label>
                </GlobCol>
        </GlobRow>

        <template v-if="selectedInterpreterCode == 'CHATGPTCUSTOM'">
            <CHATGPTCUSTOM 
                :values="interpreter.ConfigurationData"
                @resetConfig="resetConfigValues()"
                @config="(config) => interpreter.ConfigurationData = config"
                @requiredFieldsFilled = "(val) => interpreterTypeConfigFilled = val">
            </CHATGPTCUSTOM>
        </template>
        <template v-else-if="selectedInterpreterCode == 'CLOUDAI'">
            <CLOUDAI 
                :values="interpreter.ConfigurationData"
                @resetConfig="resetConfigValues()"
                @config="(config) => interpreter.ConfigurationData = config"
                @requiredFieldsFilled = "(val) => interpreterTypeConfigFilled = val">
            </CLOUDAI>
        </template>
       

        <InputToggle class="mt-2" :label="$t('interpreters.active')" v-model="interpreter.Active" v-if="!isLoading"/>

       <GlobRow class="mt-8">
           <GlobCol>
               <button class="button button--primary filter-trigger mr-2" :disabled="isSaveButtonDisabled" @click="save()">{{  $t('general.button.save') }}</button>
               <button class="button button--cancel" @click="$emit('close')">{{  $t('general.button.cancel') }}</button>
           </GlobCol>
       </GlobRow>
   </GlobModal>
</template>

<script>
import { onMounted, computed, ref, reactive, watch } from 'vue';
import { useApi } from "@/plugins/api";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";

//Interpreters
import CHATGPTCUSTOM from './InterpreterTypeFields/CHATGPTCUSTOM.vue';
import CLOUDAI from './InterpreterTypeFields/CLOUDAI.vue';


export default {
    props: ['selectedItem'],
   components: {
        CHATGPTCUSTOM,
        CLOUDAI
   },
   setup(props, {emit}) {
       const { tm } = useI18n();
       const api = useApi();
       const notifications = useNotifications();
       const endpoint = api.interpreters;

       const isLoading = ref(false);

       const interpreterTypes = ref([]);
       const interpreterTypeConfigFilled = ref(false);
       const selectedInterpreterCode = ref({});
       const customParameterNeedToFill = ref(false);
       const interpreter = reactive({
           'Name': '',
           'InterpreterId': '',
           'Active': '',
           'ConfigurationData': []
       })

       onMounted(() => {
           getDependencies();
       })

       const getDependencies = () => {
           isLoading.value = true;
           endpoint.getDependencies().then((res) => {
               interpreterTypes.value = res.interpreters.map((interpreter) => {
                   return {
                       name: interpreter.Name,
                       value: interpreter.Id,
                       code: interpreter.Code,
                       item: interpreter
                   }
               })

               if(props.selectedItem)
                {
                    interpreter.Id = props.selectedItem.Id;
                    interpreter.Name = props.selectedItem.Name;
                    interpreter.InterpreterId = props.selectedItem.InterpreterId;
                    interpreter.Active = props.selectedItem.Active;
                    if(props.selectedItem.ConfigurationData)
                    {
                        interpreter.ConfigurationData = JSON.parse(props.selectedItem.ConfigurationData);
                    }else{
                        interpreter.ConfigurationData = [];
                    }
                    
                    // props.selectedItem
                }

               isLoading.value = false;
           })
       }


       

       const isSaveButtonDisabled = computed(() => {
            if(customParameterNeedToFill.value)
            {
                return !interpreter.Name || !interpreter.InterpreterId || !interpreterTypeConfigFilled.value  ? true : false;
            }

            return !interpreter.Name || !interpreter.InterpreterId ? true : false;
           
       });

       watch(() => interpreter.InterpreterId, (newVal, oldVal) => {
            customParameterNeedToFill.value = false;
            if(typeof interpreter.InterpreterId != 'object' && interpreter.InterpreterId){
                const item = interpreterTypes.value.find(i=> i.value == interpreter.InterpreterId)
                selectedInterpreterCode.value = item.code;

                if(item.item.AllowCustomParameter && item.item.CustomParameter)
                {
                    customParameterNeedToFill.value = true;
                }

            }else{
                selectedInterpreterCode.value = null;
            }
        });

       const save = () => {
           isLoading.value = true;
           endpoint.store(interpreter).then(() => {
               notifications.push({
                   msg: tm('interpreters')['saved_successfully']
               }, 'success', false, false)
               emit('updated');
               isLoading.value = false;
           });
       }

       const resetConfigValues = () => {
            interpreter.ConfigurationData = [];
            interpreterTypeConfigFilled.value = false;
       }


       return {
           isLoading,
           isSaveButtonDisabled,
           interpreter,
           interpreterTypes,
           save,
           interpreterTypeConfigFilled,
           selectedInterpreterCode,
           resetConfigValues
       }

   }

}

</script>