<template>
    <GlobModal
       :width="'1000px'"
       :minContentHeight="'430px'"
       :title="!readerSourceId ? $t('reader_setup.source_section.add_source') : $t('reader_setup.source_section.edit_source')+': '+originSourceName"
       @close="$emit('close')"
       :show="true"
   >          
   <GlobSpinner :isLoading="isLoading" />
        <!-- General details start -->
        <h4>{{ $t('reader_setup.source_section.general') }}</h4> 
        <GlobRow class="mt-4">
           <GlobCol :medium="4">
               <label>{{$t('reader_setup.source_section.name')}}*
                   <InputDynamic  
                       :type="'text'"
                       :placeholder="$t('reader_setup.source_section.name')"
                       v-model="source.Name"
                   />
               </label>
           </GlobCol>
           <GlobCol :medium="4">
               <label>
                {{$t('reader_setup.source_section.type')}}*
                   <InputDynamic  
                       :type="'virtualselect'"
                       :placeholder="$t('reader_setup.source_section.type')"
                       :data="sourceTypes"
                       v-model="source.SourceTypeId"
                       @input="configFieldsFilled = false, sourceTypeCred = {}"
                   />
               </label>
           </GlobCol>
           <GlobCol :medium="2" v-if="!isLoading">
               <label>
                   <InputToggle :label="$t('reader_setup.source_section.active')" 
                   v-model="source.Active"
                   />
               </label>
           </GlobCol>
       </GlobRow>
       <GlobRow class="mt-4">
           <GlobCol :medium="4">
               <label>{{$t('reader_setup.source_section.read_label')}}
                   <InputDynamic  
                       :type="'text'"
                       :placeholder="$t('reader_setup.source_section.read_label')"
                       v-model="source.ReadLabel"
                   />
               </label>
           </GlobCol>
           <GlobCol :medium="4">
               <label>{{$t('reader_setup.source_section.processed_label')}}
                   <InputDynamic  
                       :type="'text'"
                       :placeholder="$t('reader_setup.source_section.processed_label')"
                       v-model="source.ProcessedLabel"
                   />
               </label>
           </GlobCol>
           <GlobCol :medium="4">
               <label>{{$t('reader_setup.source_section.integrated_label')}}
                   <InputDynamic  
                       :type="'text'"
                       :placeholder="$t('reader_setup.source_section.integrated_label')"
                       v-model="source.IntegratedLabel"
                   />
               </label>
           </GlobCol>
        </GlobRow>
        <!-- General details end -->

        <!-- Source type dynamic fields start-->
        <template v-if="sourceTypeFields && sourceTypeFields[selectedSourceTypeCode]">
            <h4 class="mt-9">{{ $t('reader_setup.source_section.source_setup') }}</h4> 
            <GlobRow class="mt-4">
                <GlobCol :medium="4" 
                        :class="sourceTypeField.class"
                        v-for="(sourceTypeField, index) in sourceTypeFields[selectedSourceTypeCode]" :key="'sourceTypeField'+ index">
                    <label>{{$t(sourceTypeField.name)}}{{ sourceTypeField.required ? '*' : '' }}
                    <InputDynamic  
                        :type="'text'"
                        :placeholder="$t(sourceTypeField.name)"
                        v-model="sourceTypeCred[sourceTypeField.key]"
                        @update:modelValue="(val) => validateConfigFields(sourceTypeField.key , val)"
                    />
                </label>
                </GlobCol>
            </GlobRow>
        </template>

        <!-- Source type dynamic fields end-->

       <GlobRow class="mt-8">
           <GlobCol>
               <button class="button button--primary filter-trigger mr-2" :disabled="isSaveButtonDisabled" @click="save()">{{  $t('general.button.save') }}</button>
               <button class="button button--cancel" @click="$emit('close')">{{  $t('general.button.cancel') }}</button>
           </GlobCol>
       </GlobRow>
   </GlobModal>
</template>

<script>
import { onMounted, computed, ref, reactive } from 'vue';
import { useApi } from "@/plugins/api";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";
import fields from './../sourceTypeFields';

export default {
   props: ['readerId', 'readerSourceId'],
   setup(props, {emit}) {
       const { tm } = useI18n();
       const api = useApi();
       const notifications = useNotifications();
       const endpoint = api.readers;

       const isLoading = ref(false);

       const sourceTypes = ref([]);
       const sourceTypeFields = ref(fields);
       const source = reactive({
           'Id': props.readerSourceId,
           'Name': '',
           'SourceTypeId': '',
           'Active': '',
           'ReadLabel': '',
           'ProcessedLabel': '',
           'IntegratedLabel': ''
       })
       const sourceTypeCred = ref({});
       const originSourceName = ref(null);
       const configFieldsFilled = ref(false);

       onMounted(() => {
            if(props.readerSourceId){
                configFieldsFilled.value = true;
            }
            getDetails();
       })

       const getDetails = () => {
            isLoading.value = true;
            endpoint.getSourceDetails(props.readerId, props.readerSourceId).then((res) => {

                sourceTypes.value = res.source_types.map((sourceType) => {
                    return {
                        name: sourceType.Name,
                        value: sourceType.Id,
                        code: sourceType.Code
                    }
                })

                if(res.data){
                    const cred = res.data.Credentials;
                    delete res.data.Credentials;
                    originSourceName.value = res.data.Name;
                    Object.assign(source, res.data);
                    Object.assign(sourceTypeCred.value, cred);
                }

                isLoading.value = false;
            });
       }

       const selectedSourceTypeCode = computed (() => {
            if(source.SourceTypeId){
                return sourceTypes.value.find( i => i.value == source.SourceTypeId).code;
            }
            return null;
       })


       const isSaveButtonDisabled = computed(() => {
            if(!source.Name || !source.SourceTypeId )
            {
                return true;
            }
            
            const fields = sourceTypeFields.value[selectedSourceTypeCode.value];
            
            if(fields && fields.length && !configFieldsFilled.value)
            {
                return true;
            }

            return false;
       });

    const validateConfigFields = (key, val) => {

        let configFieldsFilledTemp = true;

        const fields = sourceTypeFields.value[selectedSourceTypeCode.value];
        fields.forEach(field => {
            if(field.required && !sourceTypeCred.value[field.key])
            {
                configFieldsFilledTemp = false;
            }
        });
         configFieldsFilled.value = configFieldsFilledTemp;
    };

    const save = () => {
           isLoading.value = true;
           endpoint.saveSource(props.readerId, {
            source: source,
            credentials:sourceTypeCred.value
           } ).then(() => {
               notifications.push({
                   title: tm('reader_setup')['source_section']['source_saved'],
                   msg: tm('reader_setup')['source_section']['source_saved_successfully']
               }, 'success', false, false)
               emit('saved');
               isLoading.value = false;
           });
       }
       return {
           isLoading,
           isSaveButtonDisabled,
           source,
           save,
           sourceTypes,
           sourceTypeFields,
           selectedSourceTypeCode,
           sourceTypeCred,
           ...props,
           originSourceName,
           configFieldsFilled,
           validateConfigFields
       }

   }

}

</script>