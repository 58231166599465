<template>
    <!-- Create reader modal start -->
    <GlobModal
       :width="'1000px'"
       :minContentHeight="'430px'"
       :title="$t('reader_setup.edit_reader')+':'+' '+orgReaderName"
       @close="$emit('close')"
       :show="true && !showSourceCreateOrEditModal && !showSourceContextEditModal && !showSenderEditModal && !selectedReaderSourceIdToDelete && !showCustomInterpreterSetup"
   >          
   <GlobSpinner :isLoading="isLoading"></GlobSpinner>
       <!-- Reader basic details start -->
        <GlobRow class="mt-4">
            <p class="reader-id">{{ $t('reader_setup.reader_id') }}: {{ reader.Id }}</p>
           <GlobCol :medium="4">
               <label>{{$t('reader_setup.reader_name')}}*
                   <InputDynamic  
                       :type="'text'"
                       :placeholder="$t('reader_setup.reader_name')"
                       v-model="reader.Name"
                   />
               </label>
           </GlobCol>
           <GlobCol :medium="2" v-if="!isLoading">
               <label>
                   <InputToggle :label="$t('reader_setup.enable')" 
                   v-model="reader.Enabled"
                   />
               </label>
           </GlobCol>
       </GlobRow>
       <GlobRow class="mt-4">
           <GlobCol :medium="4">
               <label>{{$t('reader_setup.type')}}*
                   <InputDynamic  
                       :type="'virtualselect'"
                       :placeholder="$t('reader_setup.type')"
                       :data="readerTypes"
                       v-model="reader.ReaderTypeId"
                   />
               </label>
           </GlobCol>
           <GlobCol :medium="4">
               <label>{{$t('reader_setup.model')}}*
                   <InputDynamic  
                       :type="'virtualselect'"
                       :placeholder="$t('reader_setup.model')"
                       :data="interpreterConfigs"
                       :showCustomIcon="selectedInterpreter && selectedInterpreter.AllowCustomParameter ? 'fa-solid fa-gear' : null"
                        v-model="reader.InterpreterConfigurationId"
                        @input="interpreterChanged()"
                        @iconClicked="showCustomInterpreterSetup = true"
                   />
               </label>
           </GlobCol>

           <GlobCol :medium="4">
               <label>{{$t('reader_setup.integration')}}*
                   <InputDynamic  
                       :type="'virtualselect'"
                       :placeholder="$t('reader_setup.model')"
                       :data="integrations"
                       v-model="reader.IntegrationConfigurationId"
                   />
               </label>
           </GlobCol>
       </GlobRow>  
       
       <GlobRow class="mt-8">
           <GlobCol>
               <button class="button button--primary filter-trigger mr-2" :disabled="isSaveButtonDisabled" @click="save()">{{  $t('general.button.save') }}</button>
               <button class="button button--cancel" @click="$emit('close')">{{  $t('general.button.cancel') }}</button>
           </GlobCol>
       </GlobRow>
       <!-- Reader basic details end-->

       <!-- Source data start -->
       <div class="p-relative">
            <label class="mt-8 mb-2">{{$t('reader_setup.source_section.source')}}</label>
            <label class="create-new-item" @click="showSourceCreateOrEditModal = true">{{ $t('reader_setup.source_section.add_source') }}<font-awesome-icon icon="fa-regular fa-square-plus" /></label>
       </div>
      
       <GlobRow >
            <GlobCol :medium="12">
                <GlobTable
                    :maxHeight="75"
                    :data="{
                        head: sourceTableHead,
                        body: sourceTableBody

                    }"
                    :actions = "sourceActions"
                    :itemRenderFunctions="sourceRenderFunctions"
                />
            </GlobCol>
       </GlobRow>

       <AddOrEditSource 
         v-if="showSourceCreateOrEditModal" 
         :readerId="readerId"
         :readerSourceId="selectedReaderSource ? selectedReaderSource.ReaderSourceId : null"
         @saved="showSourceCreateOrEditModal = false, selectedReaderSource = null, loadData()"
         @close="showSourceCreateOrEditModal = false, selectedReaderSource = null"/>

       <!-- Source data end -->

   </GlobModal>

   <EditSourceContext 
        v-if="showSourceContextEditModal"
        :readerId="readerId"
        :readerSource="selectedReaderSource"
        @saved="(newContext) => sourceContextSaved(newContext)"
        @close="showSourceContextEditModal = false, selectedReaderSource = null"
    />

    <!-- Delete source confirm start -->
        <GlobConsequence  
            :title="$t('reader_setup.source_section.delete_source')"
            :content="$t('reader_setup.source_section.delete_source_confirm')"
            @close="selectedReaderSourceIdToDelete = false"
            @delete="deleteSource()"
            v-if="selectedReaderSourceIdToDelete"
            />
    <!-- Delete source confirm end -->

    <EditSenders
        v-if="showSenderEditModal"
        :readerId="readerId"
        :readerSource="selectedReaderSource"
        @close="showSenderEditModal = false, selectedReaderSource = null"
    />

    <!-- Set customer interpreter/model fields start-->
    <InterpreterCustomSetup 
            :selectedInterpreter="selectedInterpreter"
            :values="reader.InterpreterSettings"
            @close="showCustomInterpreterSetup = false"
            @config="(config) => reader.InterpreterSettings = config"
            v-if="showCustomInterpreterSetup"
         />
    <!-- Set customer interpreter/model fields end-->

    
   
</template>

<script>
import { onMounted, computed, ref, reactive } from 'vue';
import { useApi } from "@/plugins/api";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";

import AddOrEditSource from './source/AddOrEdit.vue';
import EditSourceContext from './source/EditContext.vue';
import EditSenders from './sender/Edit.vue';
import InterpreterCustomSetup from './InterpreterFields/Index.vue';

export default {
   props: ['readerId'],
   components: {
    AddOrEditSource,
    EditSourceContext,
    EditSenders,
    InterpreterCustomSetup
   },
   setup(props, {emit}) {
       const { tm } = useI18n();
       const api = useApi();
       const notifications = useNotifications();
       const endpoint = api.readers;

       const isLoading = ref(false);
       const showCustomInterpreterSetup = ref(false);

       const readerTypes = ref([]);
       const interpreterConfigs = ref([]);
       const integrations = ref([]);
       const reader = reactive({
           'Name': '',
           'Enabled': '',
           'ReaderTypeId': '',
           'InterpreterConfigurationId': '',
           'IntegrationConfigurationId': '',
           'InterpreterSettings': []
       })
       const orgReaderName = ref('');

       onMounted(() => {
           loadData();
       })

       const loadData = () => {
           isLoading.value = true;
           endpoint.getById(props.readerId).then((res) => {
               readerTypes.value = res.reader_types.map((readerType) => {
                   return {
                       name: readerType.Name,
                       value: readerType.Id
                   }
               })
               interpreterConfigs.value = res.interpreters.map((interpreter) => {
                   return {
                       name: interpreter.Name,
                       value: interpreter.Id,
                       item: interpreter
                   }
               })

               integrations.value = res.integrations.map((integration) => {
                   return {
                       name: integration.Name,
                       value: integration.Id
                   }
               })

               res.data.InterpreterSettings = res.data.InterpreterSettings ? JSON.parse(res.data.InterpreterSettings): [];

               Object.assign(reader, res.data);

               interpreterChanged();

               reader.InterpreterSettings = res.data.InterpreterSettings;
               orgReaderName.value = res.data.Name;

               sourceTableBody.value = res.data.sources;
               
               isLoading.value = false;
           })
       }


       const selectedInterpreter = ref(null);

        const interpreterChanged = () => {
            if(reader.InterpreterConfigurationId && typeof reader.InterpreterConfigurationId != 'object')
            {
                reader.InterpreterSettings = [];
                selectedInterpreter.value = interpreterConfigs.value.find( i => i.value == reader.InterpreterConfigurationId).item;
            }
        }
       

       const isSaveButtonDisabled = computed(() => {
           return !reader.Name ||
                   !reader.ReaderTypeId || 
                   !reader.InterpreterConfigurationId || 
                   !reader.IntegrationConfigurationId  ? true : false;
       });

       const save = () => {
           isLoading.value = true;
           endpoint.update(reader.Id, reader).then(() => {
               notifications.push({
                   title: tm('reader_setup')['reader_updated'],
                   msg: tm('reader_setup')['reader_updated_successfully']
               }, 'success', false, false)
               emit('updated');
               isLoading.value = false;
           });
       }

       /** Source section start */
        const showSourceCreateOrEditModal = ref(false);
        const showSourceContextEditModal = ref(false);
        const showSenderEditModal = ref(false);
        const selectedReaderSource = ref(null);
        const selectedReaderSourceIdToDelete = ref(null);
        const sourceTableBody = ref([]);
        const sourceTableHead = ref([
                {
                    Alias: 'ReaderSourceName',
                    Value: computed(() => tm('reader_setup')['source_section']['name'])
                },
                {
                    Alias: 'ReaderSourceType',
                    Value: computed(() => tm('reader_setup')['source_section']['type'])
                },
                {
                    Alias: 'ReaderSourceActive',
                    Value: computed(() => tm('reader_setup')['source_section']['active'])
                }
        ]);

        const sourceActions = computed(() => {
            return [
                {
                    name: tm('general')['button']['edit'],
                    type: "secondary",
                    action: (obj) => {
                        selectedReaderSource.value = obj;
                        showSourceCreateOrEditModal.value = true;
                    }
                },
                {
                    name: tm('reader_setup')['source_section']['context'],
                    type: "ternary",
                    action: (obj) => {
                        selectedReaderSource.value = obj;
                        showSourceContextEditModal.value = true;
                    }
                },
                {
                    name: tm('reader_setup')['source_section']['senders'],
                    type: "primary",
                    action: (obj) => {
                        selectedReaderSource.value = obj;
                        showSenderEditModal.value = true;
                    }
                },
                {
                    name: tm('general')['button']['delete'],
                    type: "delete",
                    action: (obj) => {
                        selectedReaderSourceIdToDelete.value = obj.ReaderSourceId;
                    }
                }
            ];
        });

        const sourceRenderFunctions = computed(() => {
            return {
                ReaderSourceActive(obj) {
                    if(obj.ReaderSourceActive){
                        return '<span class="check-icon"><i class="fa-solid fa-check"></i></span>';
                    }
                    return;
                },
            }
        })

        const sourceContextSaved = (newContext) => {
            const index = sourceTableBody.value.findIndex( i => i.ReaderSourceId == selectedReaderSource.value.ReaderSourceId);
            sourceTableBody.value[index].GeneralSourceContext = newContext;

            showSourceContextEditModal.value = false;
            selectedReaderSource.value = null;
        }

        const deleteSource = () => {
            isLoading.value = true;
            const id = selectedReaderSourceIdToDelete.value;
            selectedReaderSourceIdToDelete.value = null;
            endpoint.deleteSource(props.readerId, id).then((res) => {
                notifications.push({
                   title: tm('reader_setup')['source_deleted'],
                   msg: tm('reader_setup')['source_deleted_successfully']
               }, 'success', false, false)
               sourceTableBody.value = res.sources;
               isLoading.value = false
            })
        }

       /** Source section end */

       return {
            ...props,
           isLoading,
           loadData,
           readerTypes,
           interpreterConfigs,
           integrations,
           isSaveButtonDisabled,
           reader,
           save,
           selectedInterpreter,
            showCustomInterpreterSetup,
            interpreterChanged,
           orgReaderName,
           /** Source section start */
           sourceTableBody,
           sourceTableHead,
           sourceActions,
           showSourceCreateOrEditModal,
           selectedReaderSourceIdToDelete,
           deleteSource,
           selectedReaderSource,
           sourceRenderFunctions,
           showSourceContextEditModal,
           sourceContextSaved,
           showSenderEditModal
           /** Source section end */


       }

   }

}

</script>

<style lang="scss" scoped>
p.reader-id{
    position: absolute;
    right: 9%;
    top: 4%;
    margin: 0;
    font-size: 16px;
    color: $light-text-color;
    font-weight: 400;
}

label.create-new-item {
    top: 3%;
}
</style>