<template>
     <!-- Create reader modal start -->
     <GlobModal
        :width="'1000px'"
        :minContentHeight="'430px'"
        :title="$t('reader_setup.create_reader')"
        @close="$emit('close')"
        :show="true && !showCustomInterpreterSetup"
    >          
    <GlobSpinner :isLoading="isLoading"></GlobSpinner>
        <GlobRow class="mt-4">
            <GlobCol :medium="4">
                <label>{{$t('reader_setup.reader_name')}}*
                    <InputDynamic  
                        :type="'text'"
                        :placeholder="$t('reader_setup.reader_name')"
                        v-model="reader.Name"
                    />
                </label>
            </GlobCol>
            <GlobCol :medium="2">
                <label>
                    <InputToggle :label="$t('reader_setup.enable')" 
                    v-model="reader.Enabled"
                    />
                </label>
            </GlobCol>
        </GlobRow>
        <GlobRow class="mt-4">
            <GlobCol :medium="4">
                <label>{{$t('reader_setup.type')}}*
                    <InputDynamic  
                        :type="'virtualselect'"
                        :placeholder="$t('reader_setup.type')"
                        :data="readerTypes"
                        v-model="reader.ReaderTypeId"
                    />
                </label>
            </GlobCol>
            <GlobCol :medium="4">
                <label>{{$t('reader_setup.model')}}*
                    <InputDynamic  
                        :type="'virtualselect'"
                        :placeholder="$t('reader_setup.model')"
                        :data="interpreterConfigs"
                        :showCustomIcon="selectedInterpreter && selectedInterpreter.AllowCustomParameter ? 'fa-solid fa-gear' : null"
                        v-model="reader.InterpreterConfigurationId"
                        @input="interpreterChanged()"
                        @iconClicked="showCustomInterpreterSetup = true"
                    />
                </label>
            </GlobCol>

            <GlobCol :medium="4">
                <label>{{$t('reader_setup.integration')}}*
                    <InputDynamic  
                        :type="'virtualselect'"
                        :placeholder="$t('reader_setup.model')"
                        :data="integrations"
                        v-model="reader.IntegrationConfigurationId"
                    />
                </label>
            </GlobCol>
        </GlobRow>  
        
        <GlobRow class="mt-8">
            <GlobCol>
                <button class="button button--primary filter-trigger mr-2" :disabled="isSaveButtonDisabled" @click="save()">{{  $t('general.button.save') }}</button>
                <button class="button button--cancel" @click="$emit('close')">{{  $t('general.button.cancel') }}</button>
            </GlobCol>
        </GlobRow>
    </GlobModal>

    <!-- Set customer interpreter/model fields start-->
        <InterpreterCustomSetup 
            :selectedInterpreter="selectedInterpreter"
            :values="reader.InterpreterSettings"
            @close="showCustomInterpreterSetup = false"
            @config="(config) => reader.InterpreterSettings = config"
            v-if="showCustomInterpreterSetup"
         />
    <!-- Set customer interpreter/model fields end-->
</template>

<script>
import { onMounted, computed, ref, reactive } from 'vue';
import { useApi } from "@/plugins/api";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";

import InterpreterCustomSetup from './InterpreterFields/Index.vue';

export default {
    components: {
        InterpreterCustomSetup
    },
    setup(props, {emit}) {
        const { tm } = useI18n();
        const api = useApi();
        const notifications = useNotifications();
        const endpoint = api.readers;

        const isLoading = ref(false);

        const showCustomInterpreterSetup = ref(false);
        const readerTypes = ref([]);
        const interpreterConfigs = ref([]);
        const integrations = ref([]);
        const reader = reactive({
            'Name': '',
            'Enabled': '',
            'ReaderTypeId': '',
            'InterpreterConfigurationId': '',
            'IntegrationConfigurationId': '',
            'InterpreterSettings': []
        })

        onMounted(() => {
            getDependencies();
        })

        const getDependencies = () => {
            isLoading.value = true;
            endpoint.getDependencies().then((res) => {
                readerTypes.value = res.reader_types.map((readerType) => {
                    return {
                        name: readerType.Name,
                        value: readerType.Id
                    }
                })
                interpreterConfigs.value = res.interpreters.map((interpreter) => {
                    return {
                        name: interpreter.Name,
                        value: interpreter.Id,
                        item: interpreter
                    }
                })

                integrations.value = res.integrations.map((integration) => {
                    return {
                        name: integration.Name,
                        value: integration.Id
                    }
                })

                isLoading.value = false;
            })
        }


        const selectedInterpreter = ref(null);

        const interpreterChanged = () => {
            if(reader.InterpreterConfigurationId && typeof reader.InterpreterConfigurationId != 'object')
            {
                reader.InterpreterSettings = [];
                selectedInterpreter.value = interpreterConfigs.value.find( i => i.value == reader.InterpreterConfigurationId).item;
            }
        }


        

        const isSaveButtonDisabled = computed(() => {
            return !reader.Name ||
                    !reader.ReaderTypeId || 
                    !reader.InterpreterConfigurationId || 
                    !reader.IntegrationConfigurationId  ? true : false;
        });

        const save = () => {
            isLoading.value = true;
            endpoint.store(reader).then(() => {
                notifications.push({
                    title: tm('reader_setup')['reader_created'],
                    msg: tm('reader_setup')['reader_created_successfully']
                }, 'success', false, false)
                emit('created');
                isLoading.value = false;
            });
        }


        return {
            isLoading,
            readerTypes,
            interpreterConfigs,
            integrations,
            isSaveButtonDisabled,
            reader,
            save,
            selectedInterpreter,
            showCustomInterpreterSetup,
            interpreterChanged
        }

    }

}

</script>

<style></style>