<template>
  <div class="locale-changer">
    <div class="lang-action" data-toggle="example-dropdown-1" @click="toggleControls()">
		<span class="lang-name-icon-parent"><span class="lang-name-icon" :class="`fi fi-${getFlag($i18n.locale)} fis`"></span></span>
        <p class="lang-name">{{ langs.find( i => i.val == $i18n.locale).name }}</p>
        <div class="lang-action__controls" @mouseleave="closeControls" :class="isControlsToggled ? 'lang-action__controls--active' : ''">
			<div class="lang-action__controls-item">
                <span 
					class="d-flex center-items-horizontal" 
					v-for="(lang, i) in langs"
					:key="`Lang${i}`"
					@click="$i18n.locale =lang.val, setLang(lang.val)"
					>{{ lang.name }}</span>
            </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import langs from './langs.json';
import Cookies from "js-cookie";

export default {
  name: 'locale-changer',
   setup() {
    const setLang = (lang) => {
		Cookies.set('ORDERINGEST_LANG', lang);
	}
	const getFlag = (val) => {
        return langs.find(i => i.val == val) ? langs.find(i => i.val == val).flag : null
	}
	
	const isControlsToggled = ref(false);

	const toggleControls = (type = null) => {
        isControlsToggled.value = !isControlsToggled.value
    }

	const closeControls = () => {
        isControlsToggled.value = false;
    }
	
    return {
		  langs,
		  getFlag,
		  setLang,
		  closeControls,
		  isControlsToggled,
		  toggleControls
		}
	}
}
</script>

<style lang="scss" scoped>
.lang-action{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 7px;
    cursor: pointer;
    margin-left: auto;
    padding-right: 16px;
    position: relative;
    p.lang-name{
        margin: 0;
        font-size: 16px;
        color: #414950;
        font-weight: 500;
    }

    span.lang-name-icon{
		width: 18px;
		height: 15px;
		border-radius: 6px;
    }

}

.lang-action__controls{
    position: relative;
    z-index: 15;
    background-color: $white;
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease-in-out;
    position: absolute;
    right: 0;
    background-color: $white;
    top: 37px;
    right: 15px;
    border-radius: 5px;
    margin-top: 10px;
    &-item {
        padding-left: 18px;
        padding-right: 18px;
        border: 1px solid $primary-btn-color;
        border-radius: 6px;
        font-size: 16px;
        cursor: pointer;
        span {
            white-space: nowrap;
            padding-top: 6px;
            padding-bottom: 6px;

            svg{
                color: $orange;
                margin-right: 6px;
                font-size: 17px;
            }
        }

        span:nth-child(n+2) {
            border-top: 1px solid #0066FF
        }
    }
    &--active {
        opacity: 1;
        visibility: visible;
    }
}

.lang-action__controls::before{
    content: "";
    position: absolute;
    bottom: 100%; 
    left: 78%;
    margin-left: -7px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent; 
    border-right: 10px solid transparent;
    border-bottom: 10px solid $primary-btn-color;
}

.lang-name-icon-parent{
	background-color: #F2F7FE;
    width: 35px;
    height: 36px;
	display: flex;
    align-items: center;
    justify-content: center;
	border-radius: 10px;
}
</style>