<template>
    <div>
        <GlobRow class="mt-4">
            <GlobCol :medium="8">
                <label>{{$t('integrations.client_id')}}
                    <InputDynamic  
                        :type="'text'"
                        :placeholder="settings.client_id"
                        :disabled="true"
                    />
                </label>
            </GlobCol>
            <GlobCol :medium="6"></GlobCol>
            <template  v-if="!isApproved">
                <GlobCol :medium="8" class="text-center">
                    <button class="button button--primary mr-2 mt-4" @click="approve()">{{  $t('integrations.click_to_approve') }}</button>
                </GlobCol>
            </template>
            <template v-else>
                <GlobCol :medium="8" class="text-center">
                    <button class="button button--delete mt-4" @click="removeApproval()">{{  $t('integrations.click_to_remove_approval') }}</button>
                </GlobCol>
            </template>
            
        </GlobRow>
    </div>
</template>

<script>
import { reactive, watchEffect, onMounted, ref, onUnmounted, computed } from 'vue';
import { useApi } from "@/plugins/api";
import { useNotifications } from "@/plugins/notifications";
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';

export default {
    props: ['integration', 'redirectedData', 'settings'],
    setup(props, {emit}) {
        const { tm } = useI18n();
        const api = useApi();
        const endpoint = api.integrations;
        const notifications = useNotifications();
        const router = useRouter();
        const route = useRoute();

        const config = ref({});
        const isLoaded = ref(false);

        const removeQueryParams = () => {
            router.replace({ path: '/integrations', query: {} });
        };

        onMounted(() => {
            if(props.redirectedData && props.redirectedData.integration == 'fortnox')
            {
                config.value.access_token = props.redirectedData.item.AccessCode;

                if(config.value.access_token){
                    notifications.push({
                        msg: tm('integrations')['approved_successfully']
                    }, 'success', false, false)
                }
               removeQueryParams();
            }


        })

        onUnmounted(() => {
            emit('resetConfig')
        })

        const approve = () => {
            const url = props.settings.auth_endpoint+'?client_id='+props.settings.client_id+'&state='+getState()+'&scope='+props.settings.scopes+'&access_type=offline&response_type=code&account_type=service';
            window.location.href = url;
        }

        const removeApproval = () => {
            config.value.access_token = '';
            emit('removeApproval');
        }

        const getState = () => {
            let state = '';

            if(props.integration && props.integration.Id)
            {
                state = state+'type:intConfigId-'+props.integration.Id;
            }else{
                state = state+'type:create';
            }

            state = state+'--name:'+props.integration.Name;
            state = state+'--active:'+props.integration.Active;

            

            return state;
        }

        const isApproved = computed(() => {
            return (props.integration.Id && !props.integration.IsApprovedByUser) || (!props.integration.Id && !config.value.access_token) ? false : true;
        });

        watchEffect(() => {
        if (isApproved.value) {
            emit('requiredFieldsFilled', true);
            emit('config', config);
        } else {
            emit('requiredFieldsFilled', false);
        }
        });
        
        return {
            isLoaded,
            config,
            approve,
            ...props,
            removeApproval,
            isApproved
        }

   }

}
</script>