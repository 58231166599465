<template>
    <!-- Create integration modal start -->
    <GlobModal
       :width="'1000px'"
       :title="integration && integration.Id ? $t('integrations.edit') : $t('integrations.create')"
       @close="$emit('close')"
       :show="true"
   >          
   <GlobSpinner :isLoading="isLoading"></GlobSpinner>      
        <GlobRow class="mt-4">
                <GlobCol :medium="4">
                    <label>{{$t('integrations.name')}}*
                        <InputDynamic  
                            :type="'text'"
                            :placeholder="$t('integrations.name')"
                            v-model="integration.Name"
                        />
                    </label>
                </GlobCol>
                <GlobCol :medium="4">
                    <label>{{$t('integrations.integration_type')}}*
                        <InputDynamic  
                            :type="'virtualselect'"
                            :placeholder="$t('integrations.integration_type')"
                            :data="integrationTypes"
                            v-model="integration.IntegrationId"
                        />
                    </label>
                </GlobCol>
        </GlobRow>

        <template v-if="selectedIntegrationCode == 'ipsord'">
            <IPSORD 
                :values="integration.ConfigurationData"
                @resetConfig="resetConfigValues()"
                @config="(config) => integration.ConfigurationData = config"
                @requiredFieldsFilled = "(val) => integrationTypeConfigFilled = val">
            </IPSORD>
        </template>
        <template v-else-if="selectedIntegrationCode == 'emailresponder'">
            <EMAILRESPONDER 
                :values="integration.ConfigurationData"
                @config="(config) => integration.ConfigurationData = config"
                @resetConfig="resetConfigValues()"
                @requiredFieldsFilled = "(val) => integrationTypeConfigFilled = val">
            </EMAILRESPONDER>
        </template>
        <template v-else-if="selectedIntegrationCode == 'custom'">
            <CUSTOMINTEGRATION 
                :values="integration.ConfigurationData"
                @config="(config) => integration.ConfigurationData = config"
                @resetConfig="resetConfigValues()"
                @requiredFieldsFilled = "(val) => integrationTypeConfigFilled = val">
            </CUSTOMINTEGRATION>
        </template>
        <template v-else-if="selectedIntegrationCode == 'fortnox'">
            <FORTNOX 
                :integration="integration"
                :settings="JSON.parse(selectedIntegration.DefaultConfiguration)"
                :redirectedData="redirectedData"
                @config="(config) => integration.ConfigurationData = config"
                @resetConfig="resetConfigValues()"
                @removeApproval="removeApproval()"
                @requiredFieldsFilled = "(val) => integrationTypeConfigFilled = val">
            </FORTNOX>
        </template>
       

        <InputToggle class="mt-2" :label="$t('integrations.active')" v-model="integration.Active" v-if="!isLoading"/>

       <GlobRow class="mt-8">
           <GlobCol>
               <button class="button button--primary filter-trigger mr-2" :disabled="isSaveButtonDisabled" @click="save()">{{  $t('general.button.save') }}</button>
               <button class="button button--cancel" @click="$emit('close')">{{  $t('general.button.cancel') }}</button>
           </GlobCol>
       </GlobRow>
   </GlobModal>
</template>

<script>
import { onMounted, computed, ref, reactive, watch } from 'vue';
import { useApi } from "@/plugins/api";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";

//Integrations
import IPSORD from './IntegrationTypeFields/IPSORD.vue';
import EMAILRESPONDER from './IntegrationTypeFields/EMAILRESPONDER.vue';
import CUSTOMINTEGRATION from './IntegrationTypeFields/CUSTOM.vue';
import FORTNOX from './IntegrationTypeFields/FORTNOX.vue';


export default {
    props: ['selectedItem', 'redirectedData'],
   components: {
        IPSORD,
        EMAILRESPONDER,
        CUSTOMINTEGRATION,
        FORTNOX
   },
   setup(props, {emit}) {
       const { tm } = useI18n();
       const api = useApi();
       const notifications = useNotifications();
       const endpoint = api.integrations;
       const isMounted = ref(true)

       const isLoading = ref(false);

       const integrationTypes = ref([]);
       const integrationTypeConfigFilled = ref(false);
       const selectedIntegration = ref({});
       const selectedIntegrationCode = ref({});
       const integration = reactive({
           'Name': '',
           'IntegrationId': '',
           'Active': '',
           'ConfigurationData': []
       })

       onMounted(() => {
           getDependencies();
       })

       const removeApproval = () => {
            isLoading.value = true;
            endpoint.removeApproval(props.selectedItem.Id).then((res) => {
                emit('approvalRemoved');
                isLoading.value = false;
                getDependencies();
            });
       };

       const getDependencies = () => {
           isLoading.value = true;
           endpoint.getDependencies().then((res) => {
               integrationTypes.value = res.integrations.map((integration) => {
                   return {
                       name: integration.Name,
                       value: integration.Id,
                       code: integration.Code,
                       item: integration
                   }
               })

               if(props.selectedItem)
                {
                    integration.Id = props.selectedItem.Id;
                    integration.IsApprovedByUser = props.selectedItem.IsApprovedByUser;
                    integration.Name = props.selectedItem.Name;
                    integration.IntegrationId = props.selectedItem.IntegrationId;
                    integration.Active = props.selectedItem.Active;
                    if(props.selectedItem.ConfigurationData)
                    {
                        integration.ConfigurationData = JSON.parse(props.selectedItem.ConfigurationData);
                    }else{
                        integration.ConfigurationData = [];
                    }
                    
                    // props.selectedItem
                }

                //If redirected after third party login
                if(props.redirectedData && props.redirectedData.integration)
                {
                    integration.Name =  props.redirectedData.item.Name;
                    integration.Active =  props.redirectedData.item.Active;
                    integration.IntegrationId = res.integrations.find( i => i.Code == props.redirectedData.integration).Id;
                }

               isLoading.value = false;
           })
       }


       

       const isSaveButtonDisabled = computed(() => {
           return !integration.Name || !integration.IntegrationId || !integrationTypeConfigFilled.value ? true : false;
       });

       watch(() => integration.IntegrationId, (newVal, oldVal) => {
            if(typeof integration.IntegrationId != 'object' && integration.IntegrationId){
                const item = integrationTypes.value.find(i=> i.value == integration.IntegrationId)
                
                if(!isMounted.value){
                    integration.IsApprovedByUser = false;
                }

                selectedIntegrationCode.value = item.code;
                selectedIntegration.value = item.item;
            }else{
                selectedIntegrationCode.value = null;
                selectedIntegration.value = null;
            }

            isMounted.value = false;
        });

       const save = () => {
           isLoading.value = true;
           endpoint.store(integration).then((res) => {
               if(res.success){
                    notifications.push({
                        msg: tm('integrations')['saved_successfully']
                    }, 'success', false, false)
                    emit('updated');
               }else{
                    notifications.push({
                        msg: tm('integrations')['something_went_wrong']
                    }, 'error', false, false)
               }
               
               isLoading.value = false;
           });
       }

       const resetConfigValues = () => {
            integration.ConfigurationData = [];
            integrationTypeConfigFilled.value = false;
       }


       return {
           isLoading,
           isSaveButtonDisabled,
           integration,
           integrationTypes,
           save,
           integrationTypeConfigFilled,
           selectedIntegrationCode,
           selectedIntegration,
           resetConfigValues,
           ...props,
           removeApproval
       }

   }

}

</script>