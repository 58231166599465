<template>
    <GlobRow class="mt-4">
        <GlobCol :medium="8">
            <label>{{$t('interpreters.model_name')}}*
                <InputDynamic  
                    :type="'text'"
                    :placeholder="$t('interpreters.model_name')"
                    v-model="config.model_name"
                />
            </label>
        </GlobCol>
        <GlobCol :medium="8" class="mt-4">
            <label>{{$t('interpreters.temperature')}}*
                <InputDynamic  
                    :type="'text'"
                    :placeholder="$t('interpreters.temperature')"
                    v-model="config.temperature"
                />
            </label>
        </GlobCol>
    </GlobRow>
</template>

<script>
import { reactive, watch, onMounted, ref, onUnmounted } from 'vue';

export default {
    props: ['values'],
   setup(props, {emit}) {

        const config = reactive({
            'model_name': '',
            'temperature': ''
        })

        const isLoaded = ref(false)

        onMounted(() => {
            Object.assign(config, props.values)
            isLoaded.value = true;
        })

        onUnmounted(() => {
            Object.assign(config,{})
            emit('resetConfig')
        })


        const checkValidation = () => {
            if(!config.model_name || !config.temperature)
            {
                emit('requiredFieldsFilled', false)
            }else{
                emit('requiredFieldsFilled', true)
            }
            emit('config', config)
        }

        watch(() => config.model_name, (newVal, oldVal) => {
            checkValidation()
        });

        watch(() => config.temperature, (newVal, oldVal) => {
            checkValidation()
        });

        return {
            config,
            isLoaded
        }

   }

}
</script>