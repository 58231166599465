import { php } from './../endpoints.js';

const interpreters = {
    async get() {
        return php.get("/interpreters").then((res) => res.data);
    },
    async getDependencies() {
        return php.get("/interpreters/get-dependencies").then((res) => res.data);
    },
    async store(data) {
        return php.post("/interpreters", data).then((res) => res.data);
    },
    async deleteInterpreter(id) {
        return php.delete(`/interpreters/${id}`).then((res) => res.data);
    },
}

export default interpreters;